import React from 'react';

const PageRules = () => {
	return (
		<div>
			Rules PagePage
		</div>
	);
};

export default PageRules;