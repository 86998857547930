import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
// import App from './APP/App';
import reportWebVitals from "./reportWebVitals";
// import { createBrowserRouter, RouterProvider } from "react-router-dom";

// import router from "./APP/router";
import DefaultPage from "./UI/DefaultPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<React.StrictMode>
		{/* <RouterProvider router={router} /> */}
		<DefaultPage />
	</React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
