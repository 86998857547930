import React from 'react';
import { useNavigate } from 'react-router-dom';

import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import logo from '../../assets/logo/telecom-1-low-resolution-logo-color-on-transparent-background.png';

import CreditScoreOutlinedIcon from '@mui/icons-material/CreditScoreOutlined';
import CreditCardOutlinedIcon from '@mui/icons-material/CreditCardOutlined';
import HeadsetMicOutlinedIcon from '@mui/icons-material/HeadsetMicOutlined';
import LanguageOutlinedIcon from '@mui/icons-material/LanguageOutlined';

import MyMenuLi from './MyMenuLi';

import './MyHeader.css';
import "./responsive.css";

const menuItems = [
	{
		label: 'ინტერნეტი',
		link: '/internet',
	},
	{
		label: 'ტელეფონი',
		link: '/phone',
	},
	{
		label: 'ტელევიზია',
		link: '/tv',
	},
	{
		label: 'SMS',
		link: '/sms',
	},
	{
		label: 'კოლოკაცია',
		link: '/colocation',
	},
];

const topMenuItems = [
	{
		label: 'ჩემი შეკვეთები',
		link: '#',
		icon: <CreditScoreOutlinedIcon />,
	},
	{
		label: 'ბალანსის შევსება',
		link: '#',
		icon: <CreditCardOutlinedIcon />,
	},
	{
		label: '3 100 100',
		link: '#',
		icon: <HeadsetMicOutlinedIcon />,
	},
	{
		label: 'ქარ',
		link: '#',
		icon: <LanguageOutlinedIcon />,
	},
];

const MyHeader = (props) => {
	const navigate = useNavigate();

	const onMenuClick = (obj) => {
		console.log('clicked', obj);
		navigate(obj.link);
	};

	return (
		<div>
			<div className="header-menu">
				<div className="header-underline-items">
					<div className="header-menu">
						<ul className="header-menu-ul">
							<MyMenuLi items={topMenuItems} onClick={onMenuClick} liClass="header-menu-li" />
						</ul>
					</div>
				</div >
				<div className="header-cont">
					<header className="header">
						<nav className="navbar">
							<div className="navbar-container container">
								<div className="logo">
									<img src={logo} alt="" />
								</div>
								<input type="checkbox" name="" id="" />
								<div className="hamburger-lines">
									<span className="line line1"></span>
									<span className="line line2"></span>
									<span className="line line3"></span>
								</div>
								<ul className="menu-items">
									<li>
										<div className="private-cabinet private-cabinet-mob">
											<p onClick={() => navigate('#')} style={{ cursor: 'pointer' }}>
												<AccountCircleOutlinedIcon />
												<span className="privat-cab-text">პირადი კაბინეტი</span>
											</p>
										</div>
									</li>
									<MyMenuLi items={menuItems} onClick={onMenuClick} liActClass="header-men-li-active" />
									<MyMenuLi items={topMenuItems} onClick={onMenuClick} liClass="header-menu-li header-menu-li-mob" />
								</ul>
								<div className="private-cabinet private-cabinet-desk">
									<p onClick={() => navigate('#')} style={{ cursor: 'pointer' }}>
										<AccountCircleOutlinedIcon />
										<span className="privat-cab-text">პირადი კაბინეტი</span>
									</p>
								</div>
							</div>
						</nav>
					</header>
				</div>
			</div>
		</div>
	);
};

export default MyHeader;
