import React from 'react';
import { useLocation } from 'react-router-dom';

const MyMenuLi = (props) => {
	const items = props.items;
	const onClickEvent = props.onClick;
	const currentLink = useLocation();

	return (
		items.map((option, i) => (
			<li
				className={currentLink.pathname === option.link ? props.liActClass : props.liClass}
				key={i}
				style={{ cursor: 'pointer' }}
				onClick={
					option.link && onClickEvent ? () => onClickEvent(option) : false
				}
			>
				{option.icon}&nbsp;{option.label}
			</li>
		))
	);
};
export default MyMenuLi;