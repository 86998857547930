import React from 'react';

const PageAbout = () => {
	return (
		<div>
			About Page
		</div>
	);
};

export default PageAbout;