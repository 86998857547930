import React from 'react';

const PageFaq = () => {
	return (
		<div>
			FAQ Page
		</div>
	);
};

export default PageFaq;