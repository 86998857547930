import React, { useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import MyHeader from './components/MyHeader';
import MyFooter from './components/MyFooter';

import './DefaultPage.css';

import PageAbout from './Pages/About';
import PageRules from './Pages/Rules';
import PageFaq from './Pages/Faq';

const DefaultPage = (props) => {
	const [page, setPage] = useState();
	return (
		<BrowserRouter>
			<MyHeader />
			<Routes>
				<Route path="/about" element={<PageAbout />} />
				<Route path="/rules" element={<PageRules />} />
				<Route path="/faq" element={<PageFaq />} />
				<Route path="*" element={<span>Error 404. Page not found.</span>} />
			</Routes>
			<MyFooter />
		</BrowserRouter>
	);
};

export default DefaultPage;
