import React from 'react';
import { useNavigate } from 'react-router-dom';

import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';

import './MyFooter.css';
import './responsive.css'
import footer_logo from '../../assets/logo/telecom-1-low-resolution-logo-color-on-transparent-background2.png';
import MyMenuLi from './MyMenuLi';

const menuItemsC1 = [
	{
		label: 'ჩვენს შესახებ',
		link: '/about',
	},
	{
		label: 'ავტორიზაცია',
		link: '/authority',
	},
	{
		label: 'წესები და პირობები',
		link: '/rules',
	},
	{
		label: 'ხელშეკრულება',
		link: '/contract',
	},
	{
		label: 'ხშირად დასმული კითხვები',
		link: '/faq',
	},
	{
		label: 'ვაკანსიები',
		link: '/hr',
	},
];

const menuItemsC2 = [
	{
		icon: <LocationOnOutlinedIcon />,
		label: 'მისამართი',
		link: '/address',
	},
	{
		icon: <MailOutlinedIcon />,
		label: 'info@telecom1.ge',
		link: 'mailto:info@telecom1.ge',
	},

];

const MyFooter = (props) => {
	const navigate = useNavigate();

	const onMenuClick = (obj) => {
		console.log('clicked', obj);
		navigate(obj.link);
	};

	return (
		<footer className="footer">
			<div className="container footer-container">
				<div className="nav-elements">
					<ul className="footer-nav-ul">
						<MyMenuLi items={menuItemsC1} onClick={onMenuClick} />
					</ul>
				</div>
			</div>
			<div className="container footer-container footer-container2">
				<div className="footer-logo">
					<img src={footer_logo} alt="" />
				</div>
				<div className="nav-elements nav-elements2">
					<ul className="footer-nav-ul footer-nav-ul2">
						<MyMenuLi items={menuItemsC2} onClick={onMenuClick} />
					</ul>
				</div>
				<div className="nav-elements nav-elements3">
					<ul className="footer-nav-ul">
						<li>
							© 2023 Telecom 1
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default MyFooter;
